<template>
	<div>
		<b-form v-if="show" @submit="onSubmit" @reset="onReset">
			<b-form-group label="Search Query:">
				<InputTextMultiSyntax
					class="shadow-sm"
					:value="form.query"
					@input="setFormField({ key: 'query', data: $event })"
					placeholder="Enter Search Query"
				/>
			</b-form-group>
			<b-form-group label="Query Language/Database:">
				<b-form-select
					disabled
					:value="form.lang"
					@input="setFormField({ key: 'lang', data: $event })"
					:options="options"
				/>
			</b-form-group>
			<b-form-group label="SEED PMIDs (one per line):">
				<b-form-textarea
					:value="form.pmids"
					@input="setFormField({ key: 'pmids', data: $event })"
					placeholder="Enter Pubmed Article IDs"
					rows="8"
				/>
			</b-form-group>
			<b-button :disabled="isLoading" type="submit" variant="outline-primary">
				<b-icon-arrow-clockwise />
				Visualize
			</b-button>
		</b-form>
		<!-- DATA COLLLECTION -->
		<b-button
			@click="showDataCollection = !showDataCollection"
			class="text-left mt-4 w-100"
			variant="primary-outline"
		>
			<b-icon-chevron-down v-if="showDataCollection" class="mr-2" />
			<b-icon-chevron-right v-else class="mr-2" />
			Data Collection
		</b-button>
		<b-collapse v-model="showDataCollection" class="mt-2">
			<b-form-checkbox
				v-model="form.collect"
				@change="setFormField({ key: 'collect', data: $event })"
				switch
			>
				<span v-if="form.collect">I consent for the query and pmids I enter in this interface to be recorded for research purposes.</span>
				<span v-else>I do not consent for the query and pmids I enter in this interface to be recorded for research purposes.</span>
			</b-form-checkbox>
			<b-card class="my-2">
				<p class="card-text">Data collection for research purposes.</p>
				<p class="card-text">
					If you consent to data collection, we will collect the query and pmids that you submit to this interface.
					Your data is not collected by any other aspect of the searchrefiner website.
					No personally identifiable data is collected or stored.
					If you do not consent to your data being collected, you must opt-out.
					You can opt-out or opt-in at any time using the toggle above.
					The data that you provide may be used for research purposes.
					For more information, please email Harry Scells at h.scells@uq.net.au.
				</p>
			</b-card>
		</b-collapse>
	</div>
</template>

<script>
import Vue from 'vue';
import {
	BForm,
	BFormGroup,
	BFormTextarea,
	BFormSelect,
	BButton,
	BIconArrowClockwise,
	BFormCheckbox,
	BCollapse,
	BCard,
	VBToggle,
	BIconChevronRight,
	BIconChevronDown
} from 'bootstrap-vue';
Vue.directive('b-toggle', VBToggle);
import { mapGetters, mapMutations } from 'vuex';

import InputTextMultiSyntax from './InputTextMultiSyntax.vue';
import loadingMixin from '../mixins/loading.js';
import axiosMixin from '../mixins/axios.js';

export default {
	name: 'TheSearchInput',
	mixins: [
		loadingMixin,
		axiosMixin
	],
	components: {
		BForm,
		BFormGroup,
		BFormTextarea,
		BFormSelect,
		BButton,
		BFormCheckbox,
		BCollapse,
		BCard,
		BIconArrowClockwise,
		InputTextMultiSyntax,
		BIconChevronRight,
		BIconChevronDown
	},
	data() {
		return {
			show: true,
			showDataCollection: false,
			options: [
				{ value: "pubmed", text: 'PubMed' },
				{ value: "medline", text: 'Ovid MEDLINE' }
			]
		}
	},
	computed: {
		...mapGetters({
			form: "search/getForm"
		})
	},
	methods: {
		...mapMutations({
			setFormField: "search/setFormField"
		}),
		onSubmit(event) {
			event.preventDefault();
			console.log("Submitting query");
			this.submitSearch(this.form);
		},
		onReset(event) {
			event.preventDefault();
			// TODO: Reset our form values
			// Trick to reset/clear native browser form validation state
			this.show = false
			this.$nextTick(() => {
				this.show = true
			})
		}
	}
}
</script>