<template>
	<div class="shadow bg-light">
		<div v-show="!isLoading">
			<div v-if="(graph_data.NumRet || graph_data.NumRel || graph_data.NumRelRet) && !isLoading" class="graph-stats shadow-sm">
				<div v-if="graph_data.NumRet"><strong>{{ graph_data.NumRet }}</strong> total citations retrieved</div>
				<div v-if="graph_data.NumRel"><strong>{{ graph_data.NumRel }}</strong> relevant citations to find</div>
				<div v-if="graph_data.NumRelRet"><strong>{{ graph_data.NumRelRet }}</strong> relevant citations found</div>
			</div>
			<div id="graph-display"></div>
		</div>
		<div v-if="isLoading" class="d-flex justify-content-center">
			<b-spinner class="my-5" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
		</div>
	</div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
const vis = require("vis-network/standalone/umd/vis-network");

import loadingMixin from '../mixins/loading.js'

export default {
	name: 'TheVisualizer',
	mixins: [loadingMixin],
	components: {
		BSpinner
	},
	data() {
		return {
			isTree: false,
			network: null,
		}
	},
	computed: {
		graph_data() {
			const data = this.$store.getters["search/getGraphData"];
			if (data && data.edges && data.nodes) {
				this.visualizeGraph(data);
				return data;
			} else {
				// If network exists, remove from dom
				if (this.network) {
					this.network.destroy();
				}
				return {};
			}
		},
		graph_options() {
			return {
				interaction: {
					navigationButtons: true
				},
				physics: {
					enabled: true,
					hierarchicalRepulsion: {
						nodeDistance: 500
					},
					repulsion: {
						nodeDistance: 800,
						springLength: 200,
						centralGravity: 0.01,
						springConstant: 0.1,
						damping: 0.2
					},
					barnesHut: {
						gravitationalConstant: -80000,
						springLength: 100,
						springConstant: 0.01,
						centralGravity: 0.2,
						damping: 1,
					},
					solver: 'barnesHut'
				},
				edges: {
					font: {
						align: "top"
					},
					smooth: {
						enabled: true,
						roundness: 1
					},
					scaling: {
						min: 10,
						max: 50,
						label: {
							enabled: true,
							min: 25,
							max: 50
						}
					},
				},
				nodes: {
					shape: "box",
					color: "#82beff",
					font: {
						color: "#333333"
					},
					scaling: {
						min: 25,
						max: 100,
						label: {
							enabled: true,
							min: 25,
							max: 100
						}
					},
					chosen: true,
					widthConstraint: true
				},
				layout: {
					improvedLayout: true,
					hierarchical: {
						enabled: this.isTree,
						blockShifting: true,
						edgeMinimization: true,
						parentCentralization: true,
						nodeSpacing: 25,
						levelSeparation: 550
					}
				}
			}
		}
	},
	methods: {
		visualizeGraph(graph_data) {
			var nodes = new vis.DataSet(graph_data.nodes);
			var edges = new vis.DataSet(graph_data.edges);

			// create a network
			var container = document.getElementById('graph-display');
			// provide the data in the vis format
			var data = {
					nodes: nodes,
					edges: edges
			};
			var options = this.graph_options;
			// initialize your network!
			this.network = new vis.Network(container, data, options);
		}
	}
}
</script>

<style scoped>
.graph-wrapper {
	background-color: rgb(200, 200, 200);
}
.graph-stats {
	position: absolute;
	top: 90px;
	right: 20px;
	background-color: #ffffff;
	padding: 8px;
	z-index: 9;
	border: .05rem solid #bcc3ce;
}
#graph-display {
	height: calc(100vh - 100px);
}
</style>