<template>
  <b-modal ref="close-warning" id="close-warning" title="Warning">
    <p class="my-4">Warning: Pressing 'close' will clear the search and any seed PMID's</p>
    <template #modal-footer>
      <b-button @click="hide()">
        Cancel
      </b-button>
      <b-button variant="danger" @click="close">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
  name: 'TheWarningModal',
	components: {
		BModal,
		BButton
	},
  mounted() {
    this.$root.$on('show-warning-modal', () => {
      this.$refs['close-warning'].show();
    });
  },
  methods: {
    hide() {
      this.$refs['close-warning'].hide();
    },
    close() {
      // Reset store
      this.$store.dispatch('resetStore');
      // Hide modal
      this.$refs['close-warning'].hide();
    }
  }
}
</script>

<style>
</style>