import axios from 'axios';

export default {
	methods: {
		submitSearch(form) {
			// Create formdata
			var form_data = new FormData();
			Object.keys(form).forEach(key => {
				form_data.append(key, this.form[key]);
			});
			// Remove newlines from query to prevent unexpected behaviour
			form_data.set("query", this.form.query.replaceAll("\n", " "))
			// // Submit to queryvis
			this.$root.$emit('set-loading', true);
			axios({
				method: 'post',
				url: 'https://searchrefiner.sr-accelerator.com/api/queryvis',
				data: form_data,
				headers: { "Content-Type": "multipart/form-data" },
			})
				.then((response) => {
					console.log(response);
					var NumRet = 0;
					if (response.data.nodes.length > 0) {
						NumRet = response.data.nodes[0].value
					}
					this.$root.$emit('set-loading', false);
					this.$store.commit('search/setGraphData', { ...response.data, NumRet });
				})
				.catch((error) => {
					console.error(error);
					alert(error);
					this.$root.$emit('set-loading', false);
				})
		}
	}
}