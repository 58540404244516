import localforage from 'localforage'
import Vue from 'vue';

const getDefaultSearch = () => {
	return {
		// Form details
		form: {
			query: "",
			pmids: "",
			lang: "pubmed",
			username: "",
			collect: true
		},
		// Graph data
		graph_data: {}
	}
}

const state = getDefaultSearch();

const getters = {
	// Get the form
	getForm: state => {
		return state.form;
	},
	// Get graph data
	getGraphData: state => {
		return state.graph_data
	}
}

const mutations = {
	reset(state) {
		Object.assign(state, getDefaultSearch());
		localforage.clear();
	},
	set(state, data) {
		Object.assign(state, data);
	},
	// Set form
	setForm(state, data) {
		// Set form
		state.form = data;
		// Update local storage
		localforage.setItem("search", state);
	},
	// Set form field
	setFormField(state, { key, data }) {
		// Set form field
		Vue.set(state.form, key, data)
		// Update local storage
		localforage.setItem("search", state);
	},
	// Set graph data
	setGraphData(state, data) {
		// Set title
		state.graph_data = data;
		// Update local storage
		localforage.setItem("search", state);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}