import Vue from 'vue'
import Vuex from 'vuex'
import metadata from './modules/metadata'
import search from './modules/search'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		metadata,
		search
	},
	actions: {
		resetStore({ commit }) {
			commit('metadata/reset');
			commit('search/reset');
		}
	}
})