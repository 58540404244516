<template>
	<div id="app">
		<!-- NAVBAR -->
		<TheNavbar />
		<!-- MAIN APP -->
		<div class="split">
			<div id="split-0" style="width: 400px">
				<SimpleBar class="p-3 search-input-wrapper">
					<TheSearchInput />
				</SimpleBar>
			</div>
			<div id="split-1">
				<TheVisualizer class="m-3" />
			</div>
		</div>
		<!-- WARNING MODAL -->
		<TheWarningModal />
	</div>
</template>

<script>
import Split from 'split.js'
import SimpleBar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import storeMixin from './mixins/store.js'
import TheNavbar from './components/TheNavbar.vue';
import TheSearchInput from './components/TheSearchInput.vue';
import TheVisualizer from './components/TheVisualizer.vue';
import TheWarningModal from './components/TheWarningModal.vue';

export default {
	name: 'App',
	mixins: [storeMixin],
	components: {
		TheNavbar,
		TheSearchInput,
		TheVisualizer,
		TheWarningModal,
		SimpleBar
	},
	mounted() {
		// Split.js
		Split(['#split-0', '#split-1'], {
			snapOffset: 0,
			sizes: [30, 70],
			minSize: [200, 300],
			maxSize: [700, Infinity],
			onDragEnd: () => {
				this.$root.$emit("split-pane-resize");
			}
		})
		// Get url parameter
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const username = urlParams.get('username');
		if (username) {
			const hashedUsername = this.hashCode(username);
			this.$store.commit('search/setFormField', {
				key: "username",
				data: hashedUsername
			});
		}
	},
	methods: {
		hashCode(string) {
			var hash = 0;
			if (string.length == 0) {
				return hash;
			}
			for (var i = 0; i < string.length; i++) {
				var char = string.charCodeAt(i);
				hash = ((hash<<5)-hash)+char;
				hash = hash & hash; // Convert to 32bit integer
			}
			return hash;
		}
	}
}
</script>

<style>
#app {
	min-height: 100vh;
	background-color: rgb(230, 230, 230);
}
.search-input-wrapper {
	height: calc(100vh - 100px);
}
/* Split.js */
.split {
	height: calc(100vh - 70px);
	display: flex;
	flex-direction: row;
}
.gutter {
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: 50%;
}
.gutter.gutter-horizontal {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
	cursor: col-resize;
}
</style>
