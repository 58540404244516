<template>
	<div>
		<!-- NAVBAR -->
		<b-navbar type="dark" variant="dark" sticky>
			<b-navbar-brand href="#" variant="light">
				SearchRefinery
				<span>
					<span v-if="!isEditTitle">- {{ title ? title : "Untitled" }}</span>
					<input v-if="isEditTitle" ref="title-edit" :value="title" placeholder="Enter project name">
				</span>
			</b-navbar-brand>
			<b-navbar-nav>
				<b-button v-if="!isEditTitle" size="sm" class="mr-2" @click="isEditTitle = true" variant="primary">Edit</b-button>
				<b-button v-if="isEditTitle" size="sm" class="mr-2" @click="saveTitle" variant="success">Save</b-button>
				<b-button v-if="isEditTitle" size="sm" class="mr-2" @click="isEditTitle = false" variant="danger">Cancel</b-button>
			</b-navbar-nav>
			<b-navbar-nav class="ml-auto">
				<img class="bg-light mr-2 rounded" height="50px" src="@/assets/uq_logo.webp" alt="UQ" />
				<b-button class="mr-2 legacy-button" @click="openLegacy">
					<img class="mr-2 searchrefiner-logo" src="@/assets/favicon.png"/>Searchrefiner Suite
				</b-button>
				<b-button class="mr-2" v-b-modal.citing variant="success">Cite</b-button>
				<b-button class="mr-2" @click="openHelp" variant="info">Help</b-button>
				<b-button class="mr-2" @click="$root.$emit('show-warning-modal')" variant="danger">Close</b-button>
			</b-navbar-nav>
		</b-navbar>
		<!-- CITING -->
		<b-modal id="citing" title="Citing" size="lg">
			<p>If you use or have used SearchRefinery as part of your own research or work, or you would like to refer to SearchRefiner as a whole, please cite us as:</p>
			<strong>Vancouver:</strong>
			<pre class="border rounded citation">Scells H, Zuccon G. searchrefiner: A Query Visualisation and Understanding Tool for Systematic Reviews. Proceedings of the 27th ACM International Conference on Information and Knowledge Management. 2018Oct17;:1939–42.</pre>
			<strong>APA 7:</strong>
			<pre class="border rounded citation">Scells, H., &amp; Zuccon, G. (2018). searchrefiner: A Query Visualisation and Understanding Tool for Systematic Reviews. Proceedings of the 27th ACM International Conference on Information and Knowledge Management, 1939–1942. https://doi.org/10.1145/3269206.3269215</pre>
			<strong>Harvard - AGPS (Australia)</strong>
			<pre class="border rounded citation">Scells, H., and Zuccon, G., 2018. searchrefiner: A Query Visualisation and Understanding Tool for Systematic Reviews. Proceedings of the 27th ACM International Conference on Information and Knowledge Management, pp.1939–1942.</pre>
			<strong>Bibtex:</strong>
			<pre class="border rounded">
@inproceedings{scells2018searchrefiner,
  Title = {searchrefiner: A Query Visualisation and Understanding Tool for Systematic Reviews},
  Author = {Scells, Harrisen and Zuccon, Guido},
  Booktitle = {Proceedings of the 27th International CIKM Conference on Information and Knowledge Management},
  Pages={1939--1942},
  Year = {2018}
}
			</pre>
			<p>
				SearchRefiner is developed at The University of Queensland by <a href="https://ielab.io/people/harry-scells">Harry Scells</a>, <a href="https://ielab.io/people/hang-li">Hang Li</a>, <a href="https://ielab.io/people/bevan-koopman">Bevan Koopman</a>, and <a href="https://ielab.io/people/guido-zuccon">Guido Zuccon</a>, with the UI managed by <a href="https://bond.edu.au/profile/connor-forbes">Connor Forbes</a> at Bond University. For any questions or enquiries, please contact Harry or Connor.</p>
		</b-modal>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { BNavbar, BNavbarBrand, BNavbarNav, BButton, BModal, VBModal } from 'bootstrap-vue';
Vue.directive('b-modal', VBModal)

export default {
	name: 'TheNavbar',
	components: {
		BNavbar,
		BNavbarBrand,
		BNavbarNav,
		BButton,
		BModal
	},
	data() {
		return {
			isEditTitle: false
		}
	},
	computed: {
		...mapGetters('metadata', {
			title: 'getTitle'
		})
	},
	methods: {
		openLegacy() {
			window.open('https://ielab.io/searchrefiner', '_blank');
		},
		openHelp() {
			window.open('https://sr-accelerator.com/#/help/searchRefinery', '_blank');
		},
		saveTitle() {
			this.$store.commit('metadata/setTitle', this.$refs['title-edit'].value);
			this.isEditTitle = false;
		}
	}
}
</script>

<style scoped>
.legacy-button {
	background-color: #5755d9;
	border-color: #5755d9;
}
.legacy-button:hover {
	background-color: #4e4cc0;
	border-color: #4e4cc0;
}
.legacy-button:focus {
	background-color: #4e4cc0 !important;
	border-color: #4846af !important;
	box-shadow: 0 0 0 0.2rem #5b5a99;
}
.citation {
	white-space: pre-wrap;       /* Since CSS 2.1 */
	background-color: rgb(245, 245, 245);
	white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
	white-space: -pre-wrap;      /* Opera 4-6 */
	white-space: -o-pre-wrap;    /* Opera 7 */
	word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.searchrefiner-logo {
	height: 20px;
	margin-top: -2px;
}
</style>
