import localforage from 'localforage';

export default {
	async beforeCreate() {
		const storageMetadata = await localforage.getItem("metadata");
		const storageSearch = await localforage.getItem("search");
		// Load values from local storage if they exist
		if (storageMetadata) {
			this.$store.commit('metadata/set', storageMetadata);
		}
		if (storageSearch) {
			this.$store.commit('search/set', storageSearch);
		}
	}
}